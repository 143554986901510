import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import HyunYuImage from "../../images/HyunYu.png";
import EricChoiImage from "../../images/EricChoi.png";
import BongakGuImage from "../../images/BongakGu.png";
import DonghyunKimImage from "../../images/DonghyunKim.png";
import DannyKimImage from "../../images/DannyKim.png";
import BokgyunMunImage from "../../images/BokgyunMun.png";
import TaehunKimImage from "../../images/TaehunKim.png";
import YeunjaeKimImage from "../../images/YeunjaeKim.png";
import { useNavigate } from "react-router-dom";

function Team() {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const goToHome = () => {
    navigate("/");
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0; // For Safari
  };

  return (
    <>
      <div className="relative">
        <Header isMenuVisible={isMenuVisible} toggleMenu={toggleMenu} />

        <div className="w-full h-[3784px] relative ">
          <div className="w-[360px] h-[2460px] left-0 top-0 absolute bg-black" />

          <div className="w-[90%] left-[22px] top-[137px] absolute opacity-70 text-white text-xs font-normal font-['PT Sans'] leading-1">
            We are creating an offline map(Map3) that will work with the
            upcoming metaverse, Web3 ecosystem. We're making a team of the best
            people to carry out the great mission.
          </div>
          <div className="left-1/2 transform -translate-x-1/2 top-[69px] absolute text-center text-white text-[28px] font-bold font-['PT Sans'] leading-loose">
            Our Team
          </div>
          <div className="w-[90%] left-[22px] top-[394px] absolute">
            <span className="text-white text-xs font-bold font-['PT Sans'] leading-none">
              • Experience
            </span>
            <br />
            <div className="ml-2">
              <span className="block text-white/70 text-xs font-normal font-['PT Sans'] leading-[1.2rem]">
                Successfully launched and exited multiple F&B brands, including
                Italian restaurants, pizzerias, churros stores, cafes, bakeries,
                donut shops, and Korean-style bars. Former software engineer
                with expertise in big data, recommendation algorithms, Ruby, and
                Android development.
                <br />
              </span>
            </div>

            <div className="mt-3">
              <span className="text-white text-xs font-bold font-['PT Sans'] leading-none">
                • Vision
              </span>
              <br />
              <div className="ml-2">
                <span className="block text-white/70 text-xs font-normal font-['PT Sans'] leading-[1.2rem]">
                  "With a deep understanding of F&B operations and expertise in
                  Web3 technology, my goal is to create a seamless connection
                  between physical spaces and digital experiences."
                </span>
              </div>
            </div>
          </div>
          <div className="w-[90%] left-[22px] top-[842px] absolute">
            <span className="text-white text-xs font-bold font-['PT Sans'] leading-none">
              • Experience
            </span>
            <br />
            <div className="ml-2">
              <span className="block text-white/70 text-xs font-normal font-['PT Sans'] leading-[1.2rem]">
                Over 5 years of experience in software development and 3 years
                in blockchain development. Holds a Master of Science in Data
                Science from Harvard.
                <br />
              </span>
            </div>

            <div className="mt-4">
              <span className="text-white text-xs font-bold font-['PT Sans'] leading-none">
                • Vision
              </span>
              <br />
              <div className="ml-2">
                <span className="block text-white/70 text-xs font-normal font-['PT Sans'] leading-[1.2rem]">
                  "Data is the key to bridging Web3 and the physical world. With
                  my background in data analysis and blockchain, I aim to build
                  a platform where users can experience real value and provide
                  meaningful data that strengthens the Web3 ecosystem."
                </span>
              </div>
            </div>
          </div>
          <div className="w-[90%] left-[22px] top-[1262px] absolute">
            <span className="text-white text-xs font-bold font-['PT Sans'] leading-none">
              • Experience
            </span>
            <br />
            <div className="ml-2">
              <span className="block text-white/70 text-xs font-normal font-['PT Sans'] leading-[1.2rem]">
                Graduated from Yonsei Univ. with a bachelor's in Integrated
                Design. Over 5 years of experience in brand design and
                illustration. Has collaborated with major brands such as SM Ent.
                and YBM.
                <br />
              </span>
            </div>

            <div className="mt-4">
              <span className="text-white text-xs font-bold font-['PT Sans'] leading-none">
                • Vision
              </span>
              <br />
              <div className="ml-2">
                <span className="block text-white/70 text-xs font-normal font-['PT Sans'] leading-[1.2rem]">
                  "I strive to create a new brand experience on the Web3
                  platform, one that resonates with users and fosters a sense of
                  connection."
                </span>
              </div>
            </div>
          </div>
          <div className="w-[90%] left-[22px] top-[1671px] absolute">
            <span className="text-white text-xs font-bold font-['PT Sans'] leading-none">
              • Experience
            </span>
            <br />
            <div className="ml-2">
              <span className="block text-white/70 text-xs font-normal font-['PT Sans'] leading-[1.2rem]">
                Over 10 years of experience in retail operations, managing large
                supermarkets, restaurants, and pop-up stores.
                <br />
              </span>
            </div>

            <div className="mt-4">
              <span className="text-white text-xs font-bold font-['PT Sans'] leading-none">
                • Vision
              </span>
              <br />
              <div className="ml-2">
                <span className="block text-white/70 text-xs font-normal font-['PT Sans'] leading-[1.2rem]">
                  "By integrating Web3 technology, I aim to build a global
                  network of partner stores, making the platform easily
                  accessible and engaging for users around the world."
                </span>
              </div>
            </div>
          </div>

          <div className="w-[90%] left-[22px] top-[2043px] absolute">
            <span className="text-white text-xs font-bold font-['PT Sans'] leading-none">
              • Experience
            </span>
            <br />
            <div className="ml-2">
              <span className="block text-white/70 text-xs font-normal font-['PT Sans'] leading-[1.2rem]">
                With over 20 years of full-stack development experience,
                including 7 years in blockchain development, specializing in
                blockchain-based data processing, smart contract design, and
                Web3 network architecture.
                <br />
              </span>
            </div>

            <div className="mt-4">
              <span className="text-white text-xs font-bold font-['PT Sans'] leading-none">
                • Vision
              </span>
              <br />
              <div className="ml-2">
                <span className="block text-white/70 text-xs font-normal font-['PT Sans'] leading-[1.2rem]">
                  "I love to create scalable solutions that integrate Web3
                  technology into daily life, delivering meaningful value to
                  users."
                </span>
              </div>
            </div>
          </div>
          <div className="w-[90%] left-[22px] top-[2450px] absolute">
            <span className="text-white text-xs font-bold font-['PT Sans'] leading-none">
              • Experience
            </span>
            <br />
            <div className="ml-2">
              <span className="block text-white/70 text-xs font-normal font-['PT Sans'] leading-[1.2rem]">
                Over 5 years of professional design experience, including roles
                in both startups and large corporations(such as Bloomberg and
                LVMH). Earned a Master’s degree from Parsons School of Design,
                specializing in UI/UX and visual design.
                <br />
              </span>
            </div>

            <div className="mt-4">
              <span className="text-white text-xs font-bold font-['PT Sans'] leading-none">
                • Vision
              </span>
              <br />
              <div className="ml-2">
                <span className="block text-white/70 text-xs font-normal font-['PT Sans'] leading-[1.2rem]">
                  “With a passion for bridging the gap between digital and
                  physical experiences, I aim to design seamless, innovative
                  solutions that deliver optimal usability and meaningful
                  connections for users.”
                </span>
              </div>
            </div>
          </div>
          <div className="w-[90%] left-[22px] top-[2902px] absolute">
            <span className="text-white text-xs font-bold font-['PT Sans'] leading-none">
              • Experience
            </span>
            <br />
            <div className="ml-2">
              <span className="block text-white/70 text-xs font-normal font-['PT Sans'] leading-[1.2rem]">
                Graduated from New York University with a bachelor's in Computer
                Science. Worked as an Access Mobility Developer at Ericsson R&D
                Center, a global networking and telecommunications company.
                <br />
              </span>
            </div>

            <div className="mt-4">
              <span className="text-white text-xs font-bold font-['PT Sans'] leading-none">
                • Vision
              </span>
              <br />
              <div className="ml-2">
                <span className="block text-white/70 text-xs font-normal font-['PT Sans'] leading-[1.2rem]">
                  "I try to unite virtual and physical realities, enabling
                  people to explore and connect through a seamless experience."
                </span>
              </div>
            </div>
          </div>
          <div className="w-[90%] left-[22px] top-[3302px] absolute">
            <span className="text-white text-xs font-bold font-['PT Sans'] leading-none">
              • Experience
            </span>
            <br />
            <div className="ml-2">
              <span className="block text-white/70 text-xs font-normal font-['PT Sans'] leading-[1.2rem]">
                Board Member at Digital Therapeutics Alliance, Corporate
                Development Analyst at Pear Therapeutics, and Operations at
                PillPack (acquired by Amazon). Doctor of Pharmacy from
                Northeastern University. Despite his young age, he has exit
                experience in the U.S. and a strong network of influential and
                capable individuals, connecting our company with strategic
                partners.
                <br />
              </span>
            </div>

            <div className="mt-4">
              <span className="text-white text-xs font-bold font-['PT Sans'] leading-none">
                • Vision
              </span>
              <br />
              <div className="ml-2">
                <span className="block text-white/70 text-xs font-normal font-['PT Sans'] leading-[1.2rem]">
                  "With my exit experience in the U.S. and extensive network, I
                  am dedicated to connecting our company with world-class talent
                  and establishing strategic partnerships that accelerate our
                  success."
                </span>
              </div>
            </div>
          </div>

          <div className="left-[143px] top-[328px] absolute text-white text-[28px] font-bold font-['PT Sans'] leading-7">
            Hyun Yu
          </div>
          <div className="left-[143px] top-[776px] absolute text-white text-[28px] font-bold font-['PT Sans'] leading-7">
            Eric Choi
          </div>
          <div className="left-[143px] top-[1196px] absolute text-white text-[28px] font-bold font-['PT Sans'] leading-7">
            Bongak Gu
          </div>
          <div className="left-[143px] top-[1604px] absolute text-white text-[28px] font-bold font-['PT Sans'] leading-7">
            Donghyun Kim
          </div>

          <div className="left-[143px] top-[362px] absolute text-[#ef5230] text-base font-normal font-['PT Sans'] leading-none">
            Founder & CEO
          </div>
          <div className="left-[143px] top-[810px] absolute text-[#ef5230] text-base font-normal font-['PT Sans'] leading-none">
            Co-founder & CTO
          </div>
          <div className="left-[143px] top-[1230px] absolute text-[#ef5230] text-base font-normal font-['PT Sans'] leading-none">
            Branding Director
          </div>
          <div className="left-[143px] top-[1638px] absolute text-[#ef5230] text-base font-normal font-['PT Sans'] leading-none">
            Operation Manager
          </div>

          <div className="left-[143px] top-[1976px] absolute text-white text-[28px] font-bold font-['PT Sans'] leading-7">
            Bokgyun Mun
          </div>
          <div className="left-[143px] top-[2010px] absolute text-[#ef5230] text-base font-normal font-['PT Sans'] leading-none">
            Technical Lead
          </div>
          <div className="left-[143px] top-[2383px] absolute text-white text-[28px] font-bold font-['PT Sans'] leading-7">
            Yeunjae Kim
          </div>
          <div className="left-[143px] top-[2417px] absolute text-[#ef5230] text-base font-normal font-['PT Sans'] leading-none">
            Product Designer
          </div>
          <div className="left-[143px] top-[2832px] absolute text-white text-[28px] font-bold font-['PT Sans'] leading-7">
            Taehun Kim
          </div>
          <div className="left-[143px] top-[2866px] absolute text-[#ef5230] text-base font-normal font-['PT Sans'] leading-none">
            Adoption Manager
          </div>
          <div className="left-[143px] top-[3223px] absolute text-white text-[28px] font-bold font-['PT Sans'] leading-7">
            Danny Kim
          </div>
          <div className="left-[143px] top-[3260px] absolute text-[#ef5230] text-base font-normal font-['PT Sans'] leading-none">
            Advisor
          </div>
          <img
            className="w-[98px] h-[136px] left-[22px] top-[242px] absolute rounded-[20px]"
            src={HyunYuImage}
            alt="Hyun Yu"
          />
          <img
            className="w-[98px] h-[136px] left-[22px] top-[690px] absolute rounded-[20px]"
            src={EricChoiImage}
            alt="Eric Choi"
          />
          <div className="w-[98px] h-[135.22px] left-[22px] top-[1111px] absolute">
            <div className="w-[98px] h-[43.19px] left-0 top-0 absolute bg-gradient-to-r from-[#ececec] to-[#e3e3e3] rounded-[20px]" />
            <img
              className="w-[98px] h-[135.22px] left-0 top-0 absolute rounded-[20px]"
              src={BongakGuImage}
              alt="Bongak Gu"
            />
          </div>
          <img
            className="w-[98px] h-[136px] left-[22px] top-[1519px] absolute rounded-[20px]"
            src={DonghyunKimImage}
            alt="Donghyun Kim"
          />
          <img
            className="w-[98px] h-[135px] left-[22px] top-[1892px] absolute rounded-[20px]"
            src={BokgyunMunImage}
            alt="Bokgyun Mun"
          />
          <img
            className="w-[98px] h-[135px] left-[22px] top-[2299px] absolute rounded-[20px]"
            src={YeunjaeKimImage}
            alt="Yeunjae Kim"
          />
          <img
            className="w-[98px] h-[135px] left-[22px] top-[2750px] absolute rounded-[20px]"
            src={TaehunKimImage}
            alt="Taehun Kim"
          />
          <img
            className="w-[98px] h-[135px] left-[22px] top-[3141px] absolute rounded-[20px]"
            src={DannyKimImage}
            alt="Danny Kim"
          />
        </div>
      </div>
      <Footer scrollToTop={goToHome} />
    </>
  );
}

export default Team;
