import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import HyunYuImage from "../../images/HyunYu.png";
import EricChoiImage from "../../images/EricChoi.png";
import BongakGuImage from "../../images/BongakGu.png";
import DonghyunKimImage from "../../images/DonghyunKim.png";
import DannyKimImage from "../../images/DannyKim.png";
import BokgyunMunImage from "../../images/BokgyunMun.png";
import TaehunKimImage from "../../images/TaehunKim.png";
import YeunjaeKimImage from "../../images/YeunjaeKim.png";

import { useNavigate } from "react-router-dom";

function Team() {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Force scroll to top when component mounts
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0; // For Safari
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const goToHome = () => {
    navigate("/");
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0; // For Safari
  };

  return (
    <div className="w-full min-h-screen bg-black relative overflow-x-hidden">
      <Header
        toggleMenu={toggleMenu}
        scrollToTop={goToHome}
        isMenuVisible={isMenuVisible}
      />

      <div className="w-[1440px] h-[3090px] relative bg-black">
        <img
          className="w-[234px] h-[323px] left-[200px] top-[283px] absolute rounded-[20px]"
          src={HyunYuImage}
          alt="Hyun Yu"
        />
        <img
          className="w-[233px] h-[322px] left-[201px] top-[687px] absolute rounded-[20px]"
          src={EricChoiImage}
          alt="Eric Choi"
        />
        <div className="w-[233.11px] h-[321.63px] left-[200.40px] top-[1091.10px] absolute">
          <div className="w-[233.11px] h-[102.73px] left-0 top-0 absolute bg-gradient-to-r from-[#ececec] to-[#e3e3e3] rounded-[20px]" />
          <img
            className="w-[233.11px] h-[321.63px] left-0 top-0 absolute rounded-[20px]"
            src={BongakGuImage}
            alt="Bongak Gu"
          />
        </div>

        <div className="w-[775px] left-[200px] top-[148px] absolute text-white text-base font-normal font-['PT Sans'] leading-normal">
          We are creating an offline map(Map3) that will work with the upcoming
          metaverse, Web3 ecosystem.
          <br />
          We're making a team of the best people to carry out the great mission.
        </div>

        <div className="w-[731px] left-[465px] top-[389px] absolute opacity-90">
          <div className="w-[768px] ">
            <div className="mb-4">
              <div className="text-white text-sm font-bold font-['PT Sans'] leading-normal mb-2">
                • Experience
              </div>
              <div className="text-white text-sm font-normal font-['PT Sans'] leading-normal pl-3">
                Successfully launched and exited multiple F&B brands, including
                Italian restaurants, pizzerias, churros stores, cafes, bakeries,
                donut shops, and Korean-style bars. Former software engineer
                with expertise in big data, recommendation algorithms, Ruby, and
                Android development.
              </div>
            </div>

            <div className="mt-6">
              <div className="text-white text-base font-bold font-['PT Sans'] leading-normal mb-2">
                • Vision
              </div>
              <div className="text-white text-sm font-normal font-['PT Sans'] leading-normal pl-3">
                "With a deep understanding of F&B operations and expertise in
                Web3 technology, my goal is to create a seamless connection
                between physical spaces and digital experiences."
              </div>
            </div>
          </div>
        </div>
        <div className="w-[784px] h-[172px] left-[465px] top-[1204px] absolute">
          <div className="mb-4">
            <div className="text-white text-sm font-bold font-['PT Sans'] leading-normal mb-2">
              • Experience
            </div>
            <div className="text-white text-sm font-normal font-['PT Sans'] leading-normal pl-3">
              Graduated from Yonsei Univ. with a bachelor’s in Integrated
              Design. Over 5 years of experience in brand design and
              illustration. Has collaborated with major brands such as SM Ent.
              and YBM.
            </div>
          </div>

          <div className="mt-6">
            <div className="text-white text-sm font-bold font-['PT Sans'] leading-normal mb-2">
              • Vision
            </div>
            <div className="text-white text-sm font-normal font-['PT Sans'] leading-normal pl-3">
              "I strive to create a new brand experience on the Web3 platform,
              one that resonates with users and fosters a sense of connection."
            </div>
          </div>
        </div>
        <div className="w-[784px] h-[172px] left-[465px] top-[1611px] absolute">
          <div className="mb-4">
            <div className="text-white text-sm font-bold font-['PT Sans'] leading-normal mb-2">
              • Experience
            </div>
            <div className="text-white text-sm font-normal font-['PT Sans'] leading-normal pl-3">
              Over 10 years of experience in retail operations, managing large
              supermarkets, restaurants, and pop-up stores.
            </div>
          </div>

          <div className="mt-6 ">
            <div className="text-white text-sm font-bold font-['PT Sans'] leading-normal mb-2">
              • Vision
            </div>
            <div className="text-white text-sm font-normal font-['PT Sans'] leading-normal pl-3">
              "By integrating Web3 technology, I aim to build a global network
              of partner stores, making the platform easily accessible and
              engaging for users around the world."
            </div>
          </div>
        </div>
        <div className="w-[784px] left-[465px] top-[789px] absolute">
          <div className="mb-4">
            <div className="text-white text-sm font-bold font-['PT Sans'] leading-normal mb-2">
              • Experience
            </div>
            <div className="text-white text-sm font-normal font-['PT Sans'] leading-normal pl-3">
              Over 5 years of experience in software development and 3 years in
              blockchain development.
              <br />
              Holds a Master of Science in Data Science from Harvard.
            </div>
          </div>

          <div className="mt-6">
            <div className="text-white text-sm font-bold font-['PT Sans'] leading-normal mb-2">
              • Vision
            </div>
            <div className="text-white text-sm font-normal font-['PT Sans'] leading-normal pl-3">
              "Data is the key to bridging Web3 and the physical world. With my
              background in data analysis and blockchain, I aim to build a
              platform where users can experience real value and provide
              meaningful data that strengthens the Web3 ecosystem."
            </div>
          </div>
        </div>

        <div className="left-[200px] top-[76px] absolute text-white text-4xl font-bold font-['PT Mono'] leading-[60px]">
          Our Team
        </div>
        <div className="w-28 left-[465px] top-[311px] absolute text-white text-[32px] font-bold font-['PT Sans'] leading-[48px] whitespace-nowrap">
          Hyun Yu
        </div>
        <div className="left-[619px] top-[320px] absolute text-[#ef5230] text-2xl font-normal font-['PT Sans'] leading-normal">
          Founder & CEO
        </div>
        <div className="w-[159px] left-[465px] top-[1128px] absolute text-white text-[32px] font-bold font-['PT Sans'] leading-[60px] whitespace-nowrap">
          Bongak Gu
        </div>
        <div className="w-[232px] left-[465px] top-[1532px] absolute text-white text-[32px] font-bold font-['PT Sans'] leading-[60px] whitespace-nowrap">
          Donghyun Kim
        </div>
        <div className="w-[125px] left-[465px] top-[710px] absolute text-white text-[32px] font-bold font-['PT Sans'] leading-[60px] whitespace-nowrap">
          Eric Choi
        </div>

        <div className="left-[662px] top-[1143px] absolute text-[#ef5230] text-2xl font-normal font-['PT Sans'] leading-normal">
          Branding Director
        </div>
        <div className="left-[723px] top-[1547px] absolute text-[#ef5230] text-2xl font-normal font-['PT Sans'] leading-normal">
          Operation Manager
        </div>
        <div className="w-[242px] left-[628px] top-[725px] absolute text-[#ef5230] text-2xl font-normal font-['PT Sans'] leading-normal">
          Co-founder & CTO
        </div>
        <div className="w-[731px] left-[465px] top-[2016px] absolute opacity-90">
          <div className="mb-4">
            <div className="text-white text-sm font-bold font-['PT Sans'] leading-normal mb-2">
              • Experience
            </div>
            <div className="text-white text-sm font-normal font-['PT Sans'] leading-normal pl-3">
              With over 20 years of full-stack development experience, including
              7 years in blockchain development, specializing in
              blockchain-based data processing, smart contract design, and Web3
              network architecture.
            </div>
          </div>

          <div className="mt-6">
            <div className="text-white text-sm font-bold font-['PT Sans'] leading-normal mb-2">
              • Vision
            </div>
            <div className="text-white text-sm font-normal font-['PT Sans'] leading-normal pl-3">
              "I love to create scalable solutions that integrate Web3
              technology into daily life, delivering meaningful value to users."
            </div>
          </div>
        </div>
        <div className="w-[731px] left-[465px] top-[2406px] absolute opacity-90">
          <div className="mb-4">
            <div className="text-white text-sm font-bold font-['PT Sans'] leading-normal mb-2">
              • Experience
            </div>
            <div className="text-white text-sm font-normal font-['PT Sans'] leading-normal pl-3">
              Over 5 years of professional design experience, including roles in
              both startups and large corporations(such as Bloomberg and LVMH).
              Earned a Master’s degree from Parsons School of Design,
              specializing in UI/UX and visual design.
            </div>
          </div>

          <div className="mt-6">
            <div className="text-white text-sm font-bold font-['PT Sans'] leading-normal mb-2">
              • Vision
            </div>
            <div className="text-white text-sm font-normal font-['PT Sans'] leading-normal pl-3">
              “With a passion for bridging the gap between digital and physical
              experiences, I aim to design seamless, innovative solutions that
              deliver optimal usability and meaningful connections for users.”
            </div>
          </div>
        </div>

        <div className="w-[731px] left-[465px] top-[2811px] absolute opacity-90">
          <div className="mb-4">
            <div className="text-white text-sm font-bold font-['PT Sans'] leading-normal mb-2">
              • Experience
            </div>
            <div className="text-white text-sm font-normal font-['PT Sans'] leading-normal pl-3">
              Graduated from New York University with a bachelor’s in Computer
              Science. Worked as an Access Mobility Developer at Ericsson R&D
              Center, a global networking and telecommunications company.
            </div>
          </div>

          <div className="mt-6">
            <div className="text-white text-sm font-bold font-['PT Sans'] leading-normal mb-2">
              • Vision
            </div>
            <div className="text-white text-sm font-normal font-['PT Sans'] leading-normal pl-3">
              "I try to unite virtual and physical realities, enabling people to
              explore and connect through a seamless experience."
            </div>
          </div>
        </div>
        <div className="w-[731px] left-[465px] top-[3201px] absolute opacity-90">
          <div className="mb-4">
            <div className="text-white text-sm font-bold font-['PT Sans'] leading-normal mb-2">
              • Experience
            </div>
            <div className="text-white text-sm font-normal font-['PT Sans'] leading-normal pl-3">
              Board Member at Digital Therapeutics Alliance, Corporate
              Development Analyst at Pear Therapeutics, and Operations at
              PillPack (acquired by Amazon). Doctor of Pharmacy from
              Northeastern University. Despite his young age, he has exit
              experience in the U.S. and a strong network of influential and
              capable individuals, connecting our company with strategic
              partners.
            </div>
          </div>

          <div className="mt-6">
            <div className="text-white text-sm font-bold font-['PT Sans'] leading-normal mb-2">
              • Vision
            </div>
            <div className="text-white text-sm font-normal font-['PT Sans'] leading-normal pl-3">
              "With my exit experience in the U.S. and extensive network, I am
              dedicated to connecting our company with world-class talent and
              establishing strategic partnerships that accelerate our success."
            </div>
          </div>
        </div>
        <div className="w-[159px] left-[465px] top-[1932px] absolute text-white text-[32px] font-bold font-['PT Sans'] leading-[60px] whitespace-nowrap">
          Bokgyun Mun
        </div>
        <div className="left-[696px] top-[1947px] absolute text-[#ef5230] text-2xl font-normal font-['PT Sans'] leading-normal">
          Technical Lead
        </div>

        <div className="w-[159px] left-[465px] top-[2327px] absolute text-white text-[32px] font-bold font-['PT Sans'] leading-[60px] whitespace-nowrap">
          Yeunjae Kim
        </div>
        <div className="left-[666px] top-[2342px] absolute text-[#ef5230] text-2xl font-normal font-['PT Sans'] leading-normal">
          Product Designer
        </div>
        <div className="w-[159px] left-[465px] top-[2732px] absolute text-white text-[32px] font-bold font-['PT Sans'] leading-[60px] whitespace-nowrap">
          Taehun Kim
        </div>
        <div className="left-[656px] top-[2747px] absolute text-[#ef5230] text-2xl font-normal font-['PT Sans'] leading-normal">
          Adoption Manager
        </div>

        <div className="w-[159px] left-[465px] top-[3122px] absolute text-white text-[32px] font-bold font-['PT Sans'] leading-[60px] whitespace-nowrap">
          Danny Kim
        </div>
        <div className="left-[656px] top-[3137px] absolute text-[#ef5230] text-2xl font-normal font-['PT Sans'] leading-normal">
          Advisor
        </div>

        <img
          className="w-[233px] h-[322px] left-[201px] top-[1495px] absolute rounded-[20px]"
          src={DonghyunKimImage}
          alt="Donghyun Kim"
        />
        <img
          className="w-[233px] h-[322px] left-[201px]  top-[1899px] absolute rounded-[20px]"
          src={BokgyunMunImage}
          alt="Bokgyun Mun"
        />
        <img
          className="w-[233px] h-[322px] left-[201px]  top-[2299px] absolute rounded-[20px]"
          src={YeunjaeKimImage}
          alt="Yeunjae Kim"
        />
        <img
          className="w-[233px] h-[322px] left-[201px]  top-[2699px] absolute rounded-[20px]"
          src={TaehunKimImage}
          alt="Taehun Kim"
        />
        <img
          className="w-[233px] h-[322px] left-[201px]  top-[3109px] absolute rounded-[20px]"
          src={DannyKimImage}
          alt="Danny Kim"
        />
      </div>

      {/* Footer wrapper with transform */}
      <div className="relative mt-[-5200px]">
        <Footer scrollToTop={goToHome} />
      </div>
    </div>
  );
}

export default Team;
